export const sectionfi = [
  {
    description: {
      fieldType: 'paragraph',
      content:''
        // 'Are you a brand or an athlete seeking sport sponsorship opportunities? Discover sbonssy, the innovative marketplace connecting you with sponsorship deals instantly.',
      // "content": "Oletko brändi tai urheilija, joka etsii urheilun sponsorointimahdollisuuksia? \nLöydä sbonssy, innovatiivinen markkinapaikka, joka yhdistää sinut sponsoroinnin\ntarjouksiin välittömästi."
    },
    numColumns: 2,
    callToAction: {
      fieldType: 'none',
      content: 'Kirjaudu',
      href: '',
    },
    sectionType: 'hero',
    sectionId: '',
    title: {
      fieldType: 'heading1',
      content: 'Link between \n brands and athletes',
    },
    sectionName: 'Johdatus markkinoille',
    blocks: [],
    appearance: {
      fieldType: 'customAppearance',
      backgroundImage: {
        id: 'f3b0b4c4-37cc-5844-9500-d3cce7c9b775',
        type: 'imageAsset',
        attributes: {
          variants: {
            scaled800: {
              height: 533,
              width: 800,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled800',
            },
            scaled1200: {
              height: 800,
              width: 1200,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled1200',
            },
            scaled2400: {
              height: 1600,
              width: 2400,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled2400',
            },
          },
          assetPath:
            '/content/pages/landing-page/section-background-018e5011-03bb-88f5-a8e3-05b3a57f29a8.jpg',
        },
      },
      textColor: 'white',
      backgroundColor: '#97b1c9',
      backgroundImageOverlay: {
        preset: 'none',
      },
    },
  },
  {
    title: {
      fieldType: 'heading2',
    },
    description: {
      fieldType: 'paragraph',
    },
    callToAction: {
      fieldType: 'none',
    },
    appearance: {
      fieldType: 'defaultAppearance',
    },
    sectionName: 'athlete team',
    sectionType: 'columns',
    numColumns: 2,
    blocks: [],
  },
  {
    "appearance": {
      "fieldType": "defaultAppearance"
    },
    "blocks": [
      {
        "alignment": "left",
        "blockName": "1",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Sign up",
          "fieldType": "internalButtonLink",
          "href": "/en/signup"
        },
        "media": {
          "aspectRatio": "auto",
          "fieldType": "none",
          "image": {
            "_ref": {}
          }
        },
        "link": {
          "fieldType": "none"
        },
        "text": {
          "content": "Our platform welcomes individuals, teams, clubs, sports associations, and managers to join.",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Are you an athlete?",
          "fieldType": "heading3"
        }
      },
      {
        "alignment": "left",
        "blockName": "2",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Sign up",
          "fieldType": "internalButtonLink",
          "href": "/en/signup"
        },
        "media": {
          "aspectRatio": "auto",
          "fieldType": "none",
          "image": {
            "_ref": {}
          }
        },
        "link": {
          "fieldType": "none"
        },
        "text": {
          "content": "Explore athletes, create campaigns and connect. Find an effective way to promote your brand.",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Are you a brand?",
          "fieldType": "heading3"
        }
      }
    ],
    "callToAction": {
      "fieldType": "none"
    },
    "description": {
      "content": "",
      "fieldType": "paragraph"
    },
    "numColumns": 2,
    "sectionId": "signupbox",
    "sectionName": "Sign up box",
    "sectionType": "columns",
    "title": {
      "fieldType": "heading2"
    }
  },
  
  {
    "appearance": {
      "fieldType": "defaultAppearance"
    },
    "blocks": [
      {
        "alignment": "left",
        "blockName": "1",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Read more",
          "fieldType": "internalButtonLink",
          "href": "/en/brand"
        },
        "media": {
          "alt": "collaboration image",
          "aspectRatio": "auto",
          "fieldType": "image",
          "image": { 
            attributes: {
              assetPath: "/content/pages/landing-page/block-media-0194ad46-1a3b-87dc-b98b-e1ad739b71c2.png",
              variants: {
                original400: {
                  height: 400,
                  name: "original400",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=400&w=400&s=57b26eec46829ad6f79d126d2ab04dd7",
                  width: 400
                },
                original800: {
                  height: 800,
                  name: "original800",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=800&w=800&s=464a91037769fa078bbdcd49ddc07201",
                  width: 800
                },
                original1200: {
                  height: 1200,
                  name: "original1200",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=1200&w=1200&s=d667db096570833bc4107c8f07d31d1c",
                  width: 1200
                },
                original2400: {
                  height: 2400,
                  name: "original2400",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=2400&w=2400&s=ca96ec793d255156947303b244def16a",
                  width: 2400
                }
              }
            },
            id: "2b2a82f3-089d-561f-b872-36e060e5fe96",
            type: "imageAsset"
          },
          link: {
            fieldType: "none"
          }
          
          
        },
        "text": {
          "content": "Our platform offers companies an easy and efficient way to be part of the future of Finnish sports. Through our platform, companies  \ncan:\n\n- Reach new target audiences \n- Increase sales\n- Find suitable partners for collaboration\n- Support athletes",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Services for brands",
          "fieldType": "heading3"
        }
      },
      {
        "alignment": "left",
        "blockName": "2",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Read more",
          "fieldType": "internalButtonLink",
          "href": "/en/athlete"
        },
        "media": {
          "alt": "collaboration image",
          "aspectRatio": "auto",
          "fieldType": "image",
          "image": {
            "id": "61210937-cd06-5b7b-a64e-92e446062ab4",
            "type": "imageAsset",
            "attributes": {
              "assetPath": "/content/pages/landing-page/block-media-0194ad47-6ce5-851f-9f20-6440bb38eabe.png",
              "variants": {
                "original400": {
                  "height": 400,
                  "name": "original400",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=400&w=400&s=114914f9381c4fc946335f2155893719",
                  "width": 400
                },
                "original800": {
                  "height": 800,
                  "name": "original800",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=800&w=800&s=227b933b399f3920169ceaacae67fb85",
                  "width": 800
                },
                "original1200": {
                  "height": 1200,
                  "name": "original1200",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=1200&w=1200&s=8915f724b897554ed1d5be498082cb6a",
                  "width": 1200
                },
                "original2400": {
                  "height": 2400,
                  "name": "original2400",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=2400&w=2400&s=85bcf44887cde3a45dac7cbc9b87506d",
                  "width": 2400
                }
              }
            }
          },
          "link": {
            "fieldType": "none"
          }
        },
        "text": {
          "content": "We help athletes build a personal brand that makes them professional and attractive partners for collaboration.With our platform, athletes can:\n\n- Create a professional media card\n- Find sponsors\n- Save money",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Services for athletes",
          "fieldType": "heading3"
        }
      }
    ],
    "callToAction": {
      "fieldType": "none"
    },
    "description": {
      "content": "Discover exciting opportunities to enhance your sports career or brand. Sign up and give a significant boost to your success in the sports industry.",
      "fieldType": "paragraph"
    },
    "sectionId": "collaboration-landing",
    "sectionName": "Yhteistyö, joka merkitsee.",
    "sectionType": "features",
    "title": {
      "content": "Collaboration that matters.",
      "fieldType": "heading2"
    }
  },
  {
    appearance: {
      fieldType: "defaultAppearance"
    },
    blocks: [],
    callToAction: {
      content: "Contact us",
      fieldType: "internalButtonLink",
      href: "/en/contact-us"
    },
    description: {
      fieldType: "paragraph"
    },
    sectionId: "more-information",
    sectionName: "Haluatko lisätietoa?",
    sectionType: "article",
    title: {
      content: "Would you like more information?",
      fieldType: "heading2"
    }
  }
  
 
];

export const sectionfiLogin = [
  {
    description: {
      fieldType: 'paragraph',
      content:''
        // 'Are you a brand or an athlete seeking sport sponsorship opportunities? Discover sbonssy, the innovative marketplace connecting you with sponsorship deals instantly.',
      // "content": "Oletko brändi tai urheilija, joka etsii urheilun sponsorointimahdollisuuksia? \nLöydä sbonssy, innovatiivinen markkinapaikka, joka yhdistää sinut sponsoroinnin\ntarjouksiin välittömästi."
    },
    numColumns: 2,
    callToAction: {
      fieldType: 'none',
      content: 'Kirjaudu',
      href: '',
    },
    sectionType: 'hero',
    sectionId: '',
    title: {
      fieldType: 'heading1',
      content: 'Link between \n brands and athletes',
    },
    sectionName: 'Johdatus markkinoille',
    blocks: [],
    appearance: {
      fieldType: 'customAppearance',
      backgroundImage: {
        id: 'f3b0b4c4-37cc-5844-9500-d3cce7c9b775',
        type: 'imageAsset',
        attributes: {
          variants: {
            scaled800: {
              height: 533,
              width: 800,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled800',
            },
            scaled1200: {
              height: 800,
              width: 1200,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled1200',
            },
            scaled2400: {
              height: 1600,
              width: 2400,
              url:
                'https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/07/e24a35d9bfde6e91bed0a1347c69158c9bba7c?auto=format&fit=clip&h=2400&w=2400&s=bf669791b2669857d4934fb85c9a0bf0',
              name: 'scaled2400',
            },
          },
          assetPath:
            '/content/pages/landing-page/section-background-018e5011-03bb-88f5-a8e3-05b3a57f29a8.jpg',
        },
      },
      textColor: 'white',
      backgroundColor: '#97b1c9',
      backgroundImageOverlay: {
        preset: 'none',
      },
    },
  },
  {
    title: {
      fieldType: 'heading2',
    },
    description: {
      fieldType: 'paragraph',
    },
    callToAction: {
      fieldType: 'none',
    },
    appearance: {
      fieldType: 'defaultAppearance',
    },
    sectionName: 'athlete team',
    sectionType: 'columns',
    numColumns: 2,
    blocks: [],
  },
  {
    "appearance": {
      "fieldType": "defaultAppearance"
    },
    "blocks": [
      {
        "alignment": "left",
        "blockName": "1",
        "blockType": "defaultBlock",
        
        "media": {
          "aspectRatio": "auto",
          "fieldType": "none",
          "image": {
            "_ref": {}
          }
        },
        "link": {
          "fieldType": "none"
        },
        "text": {
          "content": "Our platform welcomes individuals, teams, clubs, sports associations, and managers to join.",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Are you an athlete?",
          "fieldType": "heading3"
        }
      },
      {
        "alignment": "left",
        "blockName": "2",
        "blockType": "defaultBlock",
        
        "media": {
          "aspectRatio": "auto",
          "fieldType": "none",
          "image": {
            "_ref": {}
          }
        },
        "link": {
          "fieldType": "none"
        },
        "text": {
          "content": "Explore athletes, create campaigns and connect. Find an effective way to promote your brand.",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Are you a brand?",
          "fieldType": "heading3"
        }
      }
    ],
    "callToAction": {
      "fieldType": "none"
    },
    "description": {
      "content": "",
      "fieldType": "paragraph"
    },
    "numColumns": 2,
    "sectionId": "signupbox",
    "sectionName": "Sign up box",
    "sectionType": "columns",
    "title": {
      "fieldType": "heading2"
    }
  },
  
  {
    "appearance": {
      "fieldType": "defaultAppearance"
    },
    "blocks": [
      {
        "alignment": "left",
        "blockName": "1",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Read more",
          "fieldType": "internalButtonLink",
          "href": "/en/brand"
        },
        "media": {
          "alt": "collaboration image",
          "aspectRatio": "auto",
          "fieldType": "image",
          "image": { 
            attributes: {
              assetPath: "/content/pages/landing-page/block-media-0194ad46-1a3b-87dc-b98b-e1ad739b71c2.png",
              variants: {
                original400: {
                  height: 400,
                  name: "original400",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=400&w=400&s=57b26eec46829ad6f79d126d2ab04dd7",
                  width: 400
                },
                original800: {
                  height: 800,
                  name: "original800",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=800&w=800&s=464a91037769fa078bbdcd49ddc07201",
                  width: 800
                },
                original1200: {
                  height: 1200,
                  name: "original1200",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=1200&w=1200&s=d667db096570833bc4107c8f07d31d1c",
                  width: 1200
                },
                original2400: {
                  height: 2400,
                  name: "original2400",
                  url: "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/f8/421c45d15b1b5c1cbc38872f371ca8c5b71e70?auto=format&fit=clip&h=2400&w=2400&s=ca96ec793d255156947303b244def16a",
                  width: 2400
                }
              }
            },
            id: "2b2a82f3-089d-561f-b872-36e060e5fe96",
            type: "imageAsset"
          },
          link: {
            fieldType: "none"
          }
          
          
        },
        "text": {
          "content": "Our platform offers companies an easy and efficient way to be part of the future of Finnish sports. Through our platform, companies  \ncan:\n\n- Reach new target audiences \n- Increase sales\n- Find suitable partners for collaboration\n- Support athletes",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Services for brands",
          "fieldType": "heading3"
        }
      },
      {
        "alignment": "left",
        "blockName": "2",
        "blockType": "defaultBlock",
        "callToAction": {
          "content": "Read more",
          "fieldType": "internalButtonLink",
          "href": "/en/athlete"
        },
        "media": {
          "alt": "collaboration image",
          "aspectRatio": "auto",
          "fieldType": "image",
          "image": {
            "id": "61210937-cd06-5b7b-a64e-92e446062ab4",
            "type": "imageAsset",
            "attributes": {
              "assetPath": "/content/pages/landing-page/block-media-0194ad47-6ce5-851f-9f20-6440bb38eabe.png",
              "variants": {
                "original400": {
                  "height": 400,
                  "name": "original400",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=400&w=400&s=114914f9381c4fc946335f2155893719",
                  "width": 400
                },
                "original800": {
                  "height": 800,
                  "name": "original800",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=800&w=800&s=227b933b399f3920169ceaacae67fb85",
                  "width": 800
                },
                "original1200": {
                  "height": 1200,
                  "name": "original1200",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=1200&w=1200&s=8915f724b897554ed1d5be498082cb6a",
                  "width": 1200
                },
                "original2400": {
                  "height": 2400,
                  "name": "original2400",
                  "url": "https://sharetribe-assets.imgix.net/657809d6-a477-44c3-b73d-072c9bac27a7/raw/d9/39402d62b2e6fd39f6f7c9b88e01a37b1cfe16?auto=format&fit=clip&h=2400&w=2400&s=85bcf44887cde3a45dac7cbc9b87506d",
                  "width": 2400
                }
              }
            }
          },
          "link": {
            "fieldType": "none"
          }
        },
        "text": {
          "content": "We help athletes build a personal brand that makes them professional and attractive partners for collaboration.With our platform, athletes can:\n\n- Create a professional media card\n- Find sponsors\n- Save money",
          "fieldType": "markdown"
        },
        "title": {
          "content": "Services for athletes",
          "fieldType": "heading3"
        }
      }
    ],
    "callToAction": {
      "fieldType": "none"
    },
    "description": {
      "content": "Discover exciting opportunities to enhance your sports career or brand. Sign up and give a significant boost to your success in the sports industry.",
      "fieldType": "paragraph"
    },
    "sectionId": "collaboration-landing",
    "sectionName": "Yhteistyö, joka merkitsee.",
    "sectionType": "features",
    "title": {
      "content": "Collaboration that matters.",
      "fieldType": "heading2"
    }
  },
  {
    appearance: {
      fieldType: "defaultAppearance"
    },
    blocks: [],
    callToAction: {
      content: "Contact us",
      fieldType: "internalButtonLink",
      href: "/en/contact-us"
    },
    description: {
      fieldType: "paragraph"
    },
    sectionId: "more-information",
    sectionName: "Haluatko lisätietoa?",
    sectionType: "article",
    title: {
      content: "Would you like more information?",
      fieldType: "heading2"
    }
  }
  
];

